import './polyfills'
import 'shared/sentry'
import 'stylesheets/application.sass'
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import '@betterplace/shared-components'
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import '@betterplace/shared-components/style.css'
import 'whatwg-fetch'
import Rails from '@rails/ujs'
import React, { createElement } from 'react'
import ReactDOM from 'react-dom'
import autosize from 'autosize'
import shortAndSweet from 'short-and-sweet'
import {
  CentsInput,
  ClientHeader,
  CookieBanner,
  CookieBannerLink,
  CopyComponent,
  CounterWidget,
  JSONFetcher,
  ModalLink,
  PasswordReveal,
  PasswordStrengthMeter,
  PlatformAdminUI,
  PopoverIcon,
  PopoverText,
  RandomProjectHotkey,
  ShareButtons,
  StandaloneColorInput,
  SubscriptionToggle,
  Swiper,
  TimeAgo,
  TimerWidget,
  addBrowserAnnotations,
  advanceDetailsDisclosures,
  handleManuallyReportableError,
  initI18n,
  loadLocale,
  mountOnDataBehavior,
  mountOnId,
} from 'shared'
import { LoginLink, NavigationSearch, ProjectSwiper, VideoPlayer } from 'modules'
initI18n()
//TODO: Evaluate catch handler for loadLocale in order to get rid of the typescript error (https://betterplace.atlassian.net/browse/DEV-12143)
// loadLocale(document.documentElement.lang).catch(() => {
//   console.error('Could not load the translations.')
// })
// eslint-disable-next-line @typescript-eslint/no-floating-promises
loadLocale(document.documentElement.lang)

Rails.start()

declare const COMMIT_HASH: string
const msg = `
  Starting betterplace.org
  COMMIT SHA: ${COMMIT_HASH}  @ ${new Date().toISOString()}

`
console.log(msg)

let axeRunning = false
async function initAccessibilityCheck() {
  if (process.env.NODE_ENV !== 'production') {
    if (!axeRunning) {
      axeRunning = true
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const axe = require('@axe-core/react')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      await axe(React, ReactDOM, 1000)
      axeRunning = false
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  PlatformAdminUI()
  RandomProjectHotkey()
  global.PlatformAdminUI = PlatformAdminUI
  addBrowserAnnotations()

  shortAndSweet("[maxlength]:not([data-maxlength-counter='false'])", {
    counterClassName: 'module-text-counter',
    counterLabel: '{length} / {maxlength}',
    append: (el, counter) => {
      el.parentNode?.insertBefore(counter, el.nextSibling)
    },
  })

  const centsInput: NodeListOf<HTMLElement> = document.querySelectorAll('[data-behavior="cents-input"]')
  Array.from(centsInput).forEach((el) => {
    const tag = createElement(CentsInput, {
      control: el.nextSibling as HTMLInputElement,
      initialValue: el.dataset.initialValue ?? '',
    })
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(tag, el)
  })

  initAccessibilityCheck().catch((error) => {
    console.log(error)
  })

  autosize(document.querySelectorAll('textarea'))

  mountOnId(CookieBanner, 'cookie-banner')
  mountOnId(LoginLink, 'header-login-mountpoint')
  mountOnId(NavigationSearch, 'header-search-mountpoint')
  mountOnDataBehavior(ClientHeader, 'client-header')
  mountOnDataBehavior(CookieBannerLink, 'cookie-banner-link')
  mountOnDataBehavior(TimeAgo, 'time_ago')
  mountOnDataBehavior(CounterWidget, 'counter-widget') // also used via repo content-neues :(
  mountOnDataBehavior(TimerWidget, 'timer-widget') // ONLY used via repo content-spenden-sammeln :(
  mountOnDataBehavior(PasswordReveal, 'password_reveal')
  mountOnDataBehavior(PasswordStrengthMeter, 'password_strength')
  mountOnDataBehavior(SubscriptionToggle, 'subscription-toggle')
  mountOnDataBehavior(CopyComponent, 'copy-component')
  mountOnDataBehavior(PopoverIcon, 'popover-icon')
  mountOnDataBehavior(PopoverText, 'popover-text')
  mountOnDataBehavior(ShareButtons, 'share-buttons')
  mountOnDataBehavior(ModalLink, 'modal-link')
  mountOnDataBehavior(ProjectSwiper, 'project-swiper') // also used via repos content-{neues,spenden-sammeln} :(
  mountOnDataBehavior(Swiper, 'swiper')
  mountOnDataBehavior(VideoPlayer, 'video-player')
  mountLoadMoreDonationsLink()

  const colorInputs = document.querySelectorAll('[data-behavior="color-input"]')
  Array.from(colorInputs).forEach(function (el) {
    const tag = createElement(StandaloneColorInput, { control: el.nextSibling as HTMLInputElement })
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(tag, el)
  })

  const copyContents: NodeListOf<HTMLInputElement> = document.querySelectorAll('[data-behavior="copy-content"]')
  copyContents.forEach((el) => {
    el.addEventListener('click', () => {
      el.focus()
      el.select()
    })
  })

  document.querySelectorAll('[data-behavior="radio-details-group"]').forEach((el) => {
    const radios: NodeListOf<HTMLInputElement> = el.querySelectorAll('input[type=radio]')
    radios.forEach((radio) => {
      const parent = radio.parentNode as HTMLElement | undefined
      parent?.addEventListener('click', () => {
        radios.forEach((r) => {
          const p = r.parentNode as HTMLElement | undefined
          p?.classList?.remove('checked')
        })
        parent?.classList.add('checked')
      })
    })
  })

  // this will not affect tax exemptions, quittances, and other dropzone uploads
  Array.from(document.querySelectorAll('input[type="file"]')).forEach((el) => {
    el.addEventListener('change', (event) => {
      const maxMB = 10
      const maxBytes = maxMB * 1024 * 1024
      const input = event.currentTarget as HTMLInputElement
      if (input.files && input.files[0] && input.files[0].size > maxBytes) {
        alert(i18n.t('errors.messages.max_file_size'))
        input.value = null as unknown as string
      }
    })
  })

  advanceDetailsDisclosures()
})

// Quick fix to get donation list working again
const mountLoadMoreDonationsLink = () => {
  const nodes: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(`[data-behavior=load-more-donations-link]`)
  nodes.forEach((node) => {
    node.addEventListener('click', (event) => {
      event.preventDefault()
      JSONFetcher.load<{ html: string }>({
        url: node.href,
        success: ({ html }) => {
          const p = node?.parentNode as HTMLElement
          p.innerHTML = html
          mountOnDataBehavior(TimeAgo, 'time_ago')
          mountLoadMoreDonationsLink()
          PlatformAdminUI()
        },
      })
    })
  })
}

window.onunhandledrejection = (event) => {
  handleManuallyReportableError(event.reason, i18n.t('errors.messages.unhandled_promise_rejection'))
}
